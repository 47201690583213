import React from 'react'
import { Flex, Box, Text, Image, Link } from '@theme-ui/components'
import moment from 'moment'
import shortid from 'shortid'
import cloudinaryString from '../../../helpers/cloudinaryString'

export default function Index({ shoutData, variantName, ...rest }) {
  const variant = variantName ? variantName : 'shoutWidgetV1'

  return (
    <>
      {shoutData ? (
        <Box variant={`${variant}.container`} className='container' {...rest}>
          <Flex variant={`${variant}.content`} className='content'>
            <Text variant={`${variant}.title`} className='title'>
              Recent Shout:
            </Text>
            <Text variant={`${variant}.text`} as='h3' className='text'>
              {shoutData.shout.text}
            </Text>

            {shoutData?.shout?.ctas ? (
              <Flex variant={`${variant}.shoutCTAS`} className='shoutCTABtns'>
                {Object.keys(shoutData.shout.ctas).map((ctaName) => {
                  if (ctaName !== 'cta1' && ctaName !== 'cta2') {
                    return (
                      <Link
                        className='shoutCTA'
                        key={shortid.generate()}
                        variant={`${variant}.shoutCTA`}
                        href={shoutData.shout.ctas[ctaName]}
                      >
                        {ctaName}
                      </Link>
                    )
                  }
                  return ''
                })}
              </Flex>
            ) : (
              ''
            )}

            <Text variant={`${variant}.date`} className='date'>
              {moment(shoutData.shout.updatedAt).format('MMMM DD, YYYY')}
            </Text>
          </Flex>
          {!shoutData.shout.image.isDefault && (
            <Flex
              variant={`${variant}.imageContainer`}
              className='imageContainer'
            >
              {console.log(shoutData.shout.image.image.cloudinaryId)}

              <Image
                className='shoutImage'
                src={cloudinaryString(
                  'https://res.cloudinary.com/gonation/',
                  shoutData.shout.image.image.cloudinaryId
                )}
                alt='Shout_Display'
              />
            </Flex>
          )}
        </Box>
      ) : (
        ''
      )}
    </>
  )
}
