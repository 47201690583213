/**
 *
 *  rebuilds/formats component options - it grabs the right options by location index and then
 *  evalulates whether to use hardcoded data or stories from Gonation.
 *
 *
 * @param {object} options - Options from gatby config
 * @param {string} locationIndex - locationIndex of business
 * @param {string} storyId - the id of the story you wish to use in gonation
 * @param {array} allGeneral - All Stories in an Array.
 * @param {array} arrayOptions - optional arrayOptions if not provided uses default options
 *
 */

import filterComponentConfigByLocation from './filterComponentConfigByLocation'
import storySelector from './storySelector'

export default (options, locationIndex, storyId, allGeneral, arrayOptions) => {
  return {
    ...options, // * takes all options
    ...filterComponentConfigByLocation(options, locationIndex, arrayOptions), //* get the option by location index and overwrites the option
    ...storySelector(storyId, allGeneral), //* check if story id is present and use gonation story data and overwite hard coded options
  }
}
