import React, { useContext } from 'react';
import { Image } from 'theme-ui';
import checkIfExternalLink from '../../helpers/checkIfExternalLink';
import { store } from '../../Context/Store';

// * =========================================================================
// * this components does checks to find out which image to render.
// * if the config provided a external url use that.
// * if they provided a poweredImageId go fetch it from the props if its there
// * =========================================================================

const optimizeImage = (url, imageWidth) => {
  const stringComponents = url.split('https://res.cloudinary.com/gonation/');
  const imageWidthSelector = `${imageWidth ? imageWidth : 2500}`;
  const optimizedImage = `https://res.cloudinary.com/gonation/w_${imageWidthSelector}/q_auto/f_auto/${stringComponents[1]}`;
  return optimizedImage;
};

export default function ImageSelector(props) {
  const {
    image,
    poweredImages,
    locationIndex,
    imageWidth,
    clickable = true,
  } = props;

  const { globalState, dispatch } = useContext(store);

  // const { imageObjects, arrayOfImageIds } = poweredImages
  const externalImage = checkIfExternalLink(image ? image : ''); // * if the config provided an external image

  const notFoundImage =
    'https://res.cloudinary.com/gonation/image/upload/v1614006737/sites/general/404image.png'; // * backup 404 iamge

  const poweredImagesIndex = poweredImages?.arrayOfImageIds?.[
    locationIndex
  ].indexOf(props.image); // ! if it does't find any match for the images in the generator it will return  -1

  const poweredImageUrl =
    poweredImages?.imageObjects?.[locationIndex]?.[poweredImagesIndex]
      ?.imageUrl;

  const imageUrl = poweredImageUrl ? poweredImageUrl : notFoundImage;

  const poweredImageCaption = poweredImages?.imageObjects[poweredImagesIndex]
    ?.caption
    ? poweredImages?.imageObjects[poweredImagesIndex]?.caption
    : 'Branding Content';

  const imageSrc = externalImage
    ? props.image
    : optimizeImage(imageUrl, imageWidth);

  const onClickImageHandler = () =>
    dispatch({
      type: 'OPEN_LIGHTBOX',
      data: {
        uri: imageSrc,
        caption: externalImage ? '' : poweredImageCaption,
      },
    });

  return (
    <>
      <Image
        {...props}
        src={imageSrc}
        alt={externalImage ? 'branding content' : poweredImageCaption}
        onClick={clickable ? onClickImageHandler : () => {}}
      />
    </>
  );
}

// !=============================================================================
/// ! attempt to get local image from graphql to work

// import React from 'react'
// import Img from 'gatsby-image'
// import { Image } from 'theme-ui'
// import checkIfExternalLink from '../../helpers/checkIfExternalLink'

// export default function ImageSelector(props) {
//   const externalImage = checkIfExternalLink(props.image)

//   const poweredImagesIndex = props.poweredImages.arrayOfImageIds.indexOf(
//     props.image
//   ) // ! if it does't find any match it will return  -1

//   const localImagesLoaded = props?.poweredImages?.localImages // * checks local graphql images loaded

//   const poweredImageFluid = localImagesLoaded
//     ? props?.poweredImages?.localImages[poweredImagesIndex]?.childImageSharp
//         ?.fluid
//     : false // *if they have loaded get the fluid images

//   const useLocalImage = poweredImageFluid ? true : false // * if there is a local graphql image use it. else use the url link

//   return (
//     <>
//       {useLocalImage && poweredImagesIndex !== -1 ? (
//         <Img
//           fluid={
//             props.poweredImages.localImages[poweredImagesIndex].childImageSharp
//               .fluid
//           }
//         /> // this only gets shown on graphql images
//       ) : (
//         <Image
//           src={
//             externalImage || poweredImagesIndex === -1
//               ? props.image
//               : props.poweredImages.arrayOfImages[poweredImagesIndex]
//           }
//         /> // this decides wither to use the image provided in config or the powered image url
//       )}
//     </>
//   )
// }
