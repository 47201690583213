import { useContext } from 'react';
import { store } from '../Context/Store';
// import { useStaticQuery, graphql } from 'gatsby'
/**
 *
 * gets all the locations cities in in the site in an array
 * @example    const cities = useGetCities()
 * @returns {Array} - ['milford','bridgeport','norwalk']
 *
 */

const useGetCities = () => {
  // const { allBusinessData } = useStaticQuery(
  //   graphql`
  //     query getCities {
  //       allBusinessData {
  //         edges {
  //           node {
  //             city
  //           }
  //         }
  //       }
  //       configData {
  //         poweredID
  //       }
  //     }
  //   `
  // )

  const {
    globalState: { siteData },
  } = useContext(store);

  // console.log(allBusinessData.edges.map((location) => location.node.city))

  // return allBusinessData.edges.map((location) => location.node.city)
  return siteData?.locations;
};

export default useGetCities;
