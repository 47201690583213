/**
 *
 * * takes a components options and filter the
 *
 *
 * @param {string} options - Options from gatby config
 * @param {string} locationIndex - locationIndex of business
 * @param {array} arrayOptions - optional arrayOptions if not provided uses default options
 */

const defaultArrayOptions = [
  'storyId',
  'titles',
  'subtitles',
  'texts',
  'images',
  'backgroundImages',
  'ctaNames',
  'ctaLinks',
]

export default (options, locationIndex, arrayOptions = defaultArrayOptions) => {
  const filterOptionsDataByLocation = arrayOptions.map(option => {
    return { [option]: options?.[option]?.[locationIndex] }
  })

  return Object.assign(...filterOptionsDataByLocation)
}
