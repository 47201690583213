import React from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import LightSpeed from 'react-reveal/LightSpeed';
import Jump from 'react-reveal/Jump';
import Flash from 'react-reveal/Flash';
import HeadShake from 'react-reveal/HeadShake';
import Jello from 'react-reveal/Jello';
import Pulse from 'react-reveal/Pulse';
import RubberBand from 'react-reveal/RubberBand';
import Shake from 'react-reveal/Shake';
import Spin from 'react-reveal/Spin';
import Swing from 'react-reveal/Swing';
import Tada from 'react-reveal/Tada';
import Wobble from 'react-reveal/Wobble';

const possibleDirections = ['top', 'left', 'right', 'bottom'];

/**
 *
 * @component
 * A Animation Selector Component
 * @example <Button color={'#FFFFFF'}>{children}</Button>
 * @param {Object} animation -  i.e const animation = { type: 'Fade', cascade: true, directions: ['left', 'bottom'], // i.e - left, top, bottom, right,}
 * @param {children} - the children elements
 * @returns {Object} - A React HOC Animation Selector Component
 *
 */

export default function AnimationSelector({ children, animation }) {
  const { cascade, directions, delay } = animation || {};

  const animationProps = {
    cascade: cascade ? true : false,
    delay: delay ? delay : 0,
  };

  if (directions) {
    directions.forEach((direction) => {
      if (possibleDirections.includes(direction)) {
        // if the given props is in the list of directions then use if not then don;t
        animationProps[direction] = true; // injects the directions into the animation props object. i.e left:true or right:true etc
      }
    });
  }

  const renderAnimation = () => {
    switch (animation?.type) {
      case 'none':
        return <>{children}</>;
      case 'Fade':
        return <Fade {...animationProps}>{children}</Fade>;
      case 'Flip':
        return <Flip {...animationProps}>{children}</Flip>;
      case 'Rotate':
        return <Rotate {...animationProps}>{children}</Rotate>;
      case 'Zoom':
        return <Zoom {...animationProps}>{children}</Zoom>;
      case 'Bounce':
        return <Bounce {...animationProps}>{children}</Bounce>;
      case 'Slide':
        return <Slide {...animationProps}>{children}</Slide>;
      case 'Roll':
        return <Roll {...animationProps}>{children}</Roll>;
      case 'LightSpeed':
        return <LightSpeed {...animationProps}>{children}</LightSpeed>;
      // rest does not take animation props
      case 'Jump':
        return <Jump>{children}</Jump>;
      case 'Flash':
        return <Flash>{children}</Flash>;
      case 'HeadShake':
        return <HeadShake>{children}</HeadShake>;
      case 'Jello':
        return <Jello>{children}</Jello>;
      case 'Pulse':
        return <Pulse>{children}</Pulse>;
      case 'RubberBand':
        return <RubberBand>{children}</RubberBand>;
      case 'Shake':
        return <Shake>{children}</Shake>;
      case 'Spin':
        return <Spin>{children}</Spin>;
      case 'Swing':
        return <Swing>{children}</Swing>;
      case 'Tada':
        return <Tada>{children}</Tada>;
      case 'Wobble':
        return <Wobble>{children}</Wobble>;
      // default
      default:
        return <Fade>{children}</Fade>;
    }
  };

  return renderAnimation();
}
